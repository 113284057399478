<template>
	<div v-if="!loading">
		<template v-if="$store.state.company.under_maintenance">
			<auth v-if="$store.getters.isLoggedIn && bypass" />
			<maintenance v-else />
		</template>
		<template v-else>
			<guest v-if="!$store.getters.isLoggedIn" />
			<auth v-else-if="$store.getters.isLoggedIn" />
		</template>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	metaInfo: {
		title: 'Prakerja',
	},
	components: {
		auth: require("./views/template/Auth").default,
		guest: require("./views/template/Guest").default,
		maintenance: require("./views/template/Maintenance").default,
	},
	data() {
		return {
			loading: true,
			bypass: false,
		}
	},
	computed: {
		...mapState({
			site_data: state => state.company.site_data
		}),
	},

	methods: {
		getCompanySetting() {
			this.$store.dispatch("getCompanySetting").then(() => {
				this.checkBypass()
				this.loading = false
			})
		},
		checkBypass() {
			if (localStorage.getItem("ytc_lms_auth") === null) {
				this.bypass = false
			} else {
				const data = localStorage.getItem("ytc_lms_auth")
				if (this.$store.state.company.phone_number.includes(data.phone)) {
					this.bypass = true
				} else {
					this.bypass = false
				}
			}
		}
	},
	beforeMount() {
		this.getCompanySetting()

		// change favicon whit site_date.favicon.url
		const favicon = document.querySelector('link[rel="icon"]')
		favicon.href = this.site_data.favicon.url
	},
};
</script>

<style lang="scss">
.theme--light.v-application {
	background-color: var(--v-background-base);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@keyframes fade {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fade-up {
	from {
		width: 100%;
		position: absolute;
		opacity: 0;
		transform: translate3d(0, 50%, 0);
		left: 0;
		right: 0;
	}

	to {
		width: 100%;
		position: absolute;
		left: 0;
		right: 0;
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fade-enter-active {
	animation: fade 0.3s;
}

.fade-up-leave-active {
	animation: fade-up 0.3s reverse;
}

.fade-up-enter-active {
	animation: fade-up 0.3s;
}

a {
	text-decoration: none;
}
</style>