<template>
  <v-row>
    <v-col class="pt-0 pt-sm-6">
      <v-row
        v-show="loadContent"
        class="fill-height"
        align-content="center"
        justify="center"
        style="height: 60vh"
      >
        <v-col class="text-subtitle-1 text-center color-navy-soft" cols="12">
          Tunggu beberapa saat ...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-show="!loadContent" class="mb-3">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <v-tabs v-model="tab" class="transparent-tabs" :right="$vuetify.breakpoint.name =='xs'">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab @click="getData(0)">Kelas Satuan</v-tab>
                  <v-tab @click="getData(1)">Kelas Berlangganan</v-tab>
                </v-tabs>
                <v-btn v-if="$vuetify.breakpoint.name !=='xs'" color="primary" @click="modalVoucher = true">
                  Tukar voucher di sini
                </v-btn>
              </div>
              <v-btn v-if="$vuetify.breakpoint.name =='xs'" color="primary" class="float-right mb-0 mt-5" @click="modalVoucher = true">
                Tukar voucher di sini
              </v-btn>
            </v-col>

            <v-col cols="12">
              <ListClass
                ref="listClassRef"
                :isSubscribe="isSubscribe"
                @loadContentTrue="loadContentTrue"
                @loadContentFalse="loadContentFalse"
              >
              </ListClass>
            </v-col>
          </v-row>
          <!-- My class list -->
          <!-- end class list -->
        </v-container>
      </v-row>
    </v-col>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h6"> Perhatian </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-left pt-2">
          <p class="text-h6 font-weight-medium">
            Anda yakin mau reedem voucher dengan akun
            <b>{{ user.fullname }}</b> dengan no HP. {{ user.phone }} ?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false" class="mb-3"
            >Batal</v-btn
          >
          <v-btn
            color="primary"
            elevation="0"
            :loading="submitting"
            @click="submit"
            class="mb-3"
            >Ya, yakin!</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalVoucher" width="400" persistent>
      <v-card>
        <v-card-title>
          <div :class="$vuetify.breakpoint.name == 'xs' ? 'body-2 font-weight-bold' : 'subtitle-2 font-weight-bold'">
            Redeem Voucher yang Sudah Anda Beli
          </div>
          <v-spacer></v-spacer>
          <v-btn
            @click="modalVoucher = false"
            icon>
            <v-icon>ri-close-line</v-icon>
          </v-btn>
        </v-card-title>
        <!-- <v-card-title
          class="text-h6"
          style="position: relative; font-size: 17px !important"
        >
          Redeem Voucher yang Sudah Anda Beli
          <v-btn
            @click="modalVoucher = false"
            icon
            style="position: absolute; right: 0; top: 4px"
          >
            <v-icon>ri-close-line</v-icon>
          </v-btn>
        </v-card-title> -->
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-form
            @submit.prevent="submit"
            lazy-validation
            ref="form"
            v-model="valid"
          >
            <v-row no-gutters>
              <v-col cols="12" class="mb-4">
                <div class="text-center">
                  Silakan input kode voucher yang telah Anda terima
                </div>
              </v-col>
              <v-col cols="12">
                <v-alert
                  type="error"
                  text
                  v-show="response !== null"
                >
                  {{ response }}
                </v-alert>
                <v-text-field
                  outlined
                  filled
                  style="border-radius: 15px"
                  hide-details="auto"
                  placeholder="Masukkan kode voucher"
                  v-model="voucher"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-expand-transition>
                  <v-text-field
                    v-if="useprakerja"
                    class="mt-4"
                    style="border-radius: 15px"
                    outlined
                    filled
                    hide-details="auto"
                    placeholder="Masukkan kode redeem (khusus user Kartu Prakerja)"
                    v-model="meta.pmo_voucher"
                    required
                  >
                  </v-text-field>
                </v-expand-transition>
              </v-col>
              <v-col cols="12">
                <div
                  class="d-flex justify-space-between align-center my-4 ml-2 primary--text"
                  style="cursor: pointer"
                  @click="
                    useprakerja = !useprakerja;
                    meta.pmo_voucher = '';
                  "
                >
                  <div>Gunakan Kode Redeem Kartu Prakerja</div>
                  <v-divider
                    class="mx-4"
                    style="border-color: #324577"
                  ></v-divider>
                  <v-icon color="primary">
                    {{ useprakerja ? "mdi-chevron-up" : "mdi-chevron-down" }}
                  </v-icon>
                </div>
                <!-- <v-checkbox
                                v-model="useprakerja"
                                hide-details
                                class="mb-4 ml-1 pa-0"
                                :label="`Gunakan Kode Redeem Kartu Prakerja`">
                              </v-checkbox> -->
                <v-btn
                  color="primary"
                  elevation="0"
                  block
                  style="border-radius: 10px"
                  :disabled="!voucher || (useprakerja && !meta.pmo_voucher)"
                  @click="dialog = true"
                >
                  Tukar Kode Voucher
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ListClass from "./ListClass.vue";

export default {
  name: "my-class",
  data() {
    return {
      tab: 0,
      modalVoucher: false,
      loadContent: false,
      useprakerja: false,
      meta: {
        pmo_voucher: "",
      },
      voucher: "",
      referral: "",
      valid: true,
      submitting: false,
      response: null,
      dialog: false,
      isSubscribe: 0,
    };
  },
  components: {
    ListClass,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.getData(0);
  },
  methods: {
    goToTutorRedeem() {
      this.$router.push({ name: "prakerja", hash: "#redeem" });
    },
    loadContentTrue() {
      this.loadContent = true;
    },
    loadContentFalse() {
      this.loadContent = false;
    },
    getData(val) {
      this.isSubscribe = val;
      this.$refs.listClassRef.getClass(true, val);
    },
    submit() {
      this.submitting = true;
      let meta = {
        pmo_voucher: this.meta.pmo_voucher,
      };
      let code = this.voucher;
      let referral = this.referral;
      if (code != "") {
        if (this.meta.pmo_voucher && this.useprakerja) {
          setTimeout(() => {
            this.axios
              .post(
                `/users/v1/myprogram/redeem-prakerja`,
                { meta, code },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => {
                let res = response.data;
                this.submitting = false;
                this.useprakerja = false;
                this.getData(0);
                this.tab = 0;

                setTimeout(() => {
                  window.open(res.data.url, "_blank");
                }, 10);
                this.$refs.form.reset();
                this.dialog = false;
                this.modalVoucher = false;
              })
              .catch((err) => {
                this.getData(0);
                this.tab = 0;
                this.dialog = false;
                this.submitting = false;
                this.useprakerja = false;
                this.$refs.form.reset();
                this.response = err.response.data.message;
                setTimeout(() => {
                  this.response = null;
                }, 5000);
              });
          }, 1000);
        } else {
          setTimeout(() => {
            this.axios
              .post(
                `/users/v1/myprogram/redeem`,
                { meta, code, referral },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then(() => {
                // let res = response.data
                // console.log('else',res);
                this.submitting = false;
                this.getData(0);
                this.$refs.form.reset();
                this.dialog = false;
                this.modalVoucher = false;
              })
              .catch((res) => {
                this.dialog = false;
                this.submitting = false;
                this.$refs.form.reset();
                this.response = res.response.data.message;
                setTimeout(() => {
                  this.response = null;
                }, 5000);
              });
          }, 1000);
        }
      } else {
        this.submitting = false;
        this.$refs.form.reset();
        this.response = true;
        this.response = "Kode Lisensi tidak boleh kosong";
        setTimeout(() => {
          this.response = null;
        }, 5000);
      }
    },
  },
};
</script>

<style type="text/css">
.text-dots-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
