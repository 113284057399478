<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- Sidenav / Navigation drawer -->
      <div>
        <v-dialog v-model="attend" width="500" persistent>
          <v-card class="text-center">
            <v-card-title class="text-center text-h5 d-block">
              Perhatian
            </v-card-title>

            <v-card-text class="pb-0">
              <p class="fs-16 color-strike">
                Bagi pengguna Kartu Prakerja, pastikan data diri Anda
                terutama nama sesuai dengan akun Kartu Prakerja. Perbedaan
                identitas dapat menyebabkan sertifikat tidak keluar.
              </p>
              <v-alert type="warning" dense color="#8492A6" icon="ri-information-line" class="text-left border-0 fs-16">
                Untuk akses pelatihan lebih mudah, kami sarankan menggunakan
                perangkat Android, Laptop, atau PC. Apabila ada kendala silahkan
                hubungi CS kami.
              </v-alert>
            </v-card-text>
            <v-card-actions class="pb-3 d-block">
              <v-btn color="primary" @click="attend = false" depressed class="px-4 text-center">
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Topnav / App bar -->
        <v-main class="grey">
          <v-app-bar class="white" flat fixed>
            <v-toolbar-title>
              <div class="my-1 text-left">
                <img src="@/assets/images/logo-dash.png" alt="Avatar" class="img-yec-test" />
              </div>
            </v-toolbar-title>
            <v-list dense flat class="d-none d-md-block">
              <v-list-item-group color="primary" style="display: flex">
                <div v-for="(item, i) in items" :key="i">
                  <v-list-item :disabled="!user.is_updated_profile && item.title != 'Account'
                    " class="py-1 pl-6" active-class="menu-active" :to="item.link" @click="pageChanged(0, item)">
                    <v-list-item-icon v-if="item.title === 'Beranda'" class="mr-2 fs-14">
                      <v-icon class="color--text" :class="user.is_updated_profile ||
                        item.title == 'Account' ||
                        'text--lighten-3'
                        ">{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="fs-14 color--text" :class="user.is_updated_profile ||
                        item.title == 'Account' ||
                        'text--lighten-3'
                        ">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list-item-group>
            </v-list>
            <v-spacer></v-spacer>

            <!-- NOTIFICATION -->
            <v-menu right :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-badge :content="total" :value="total" color="primary" overlap class="mx-3">
                  <v-icon v-bind="attrs" v-on="on" @click="getAll()"> ri-notification-3-fill</v-icon>
                </v-badge>
              </template>

              <v-card class="mt-3" elevation="6">
                <v-card-title class="pt-2 pb-2 mb-0 text-subtitle-1 font-weight-bold">
                  Notifikasi
                </v-card-title>
                <v-divider></v-divider>
                <v-tabs grow background-color="primary" class="notif">
                  <v-tab class="text-capitalize white--text" @click="getAll('')">Semua</v-tab>
                  <v-tab class="text-capitalize white--text" @click="getAll('reads=1')">Dibaca</v-tab>
                  <v-tab class="text-capitalize white--text" @click="getAll('reads=0')">Belum Dibaca</v-tab>
                </v-tabs>
                <v-list dense class="" style="width: 360px;max-height: 450px;overflow-y: scroll;">
                  <v-list-item v-if="notif.length == 0">
                    <v-list-item-content>Belum ada notifikasi</v-list-item-content>
                  </v-list-item>

                  <v-list-item v-else v-for="(item, i) in notif" :key="i" style="border-bottom:1px solid #0000001f;"
                    @click="changeStatus(item.id)" :class="{ 'bgRead': item.read === false }">
                    <v-list-item-content>
                      <div class="my-1 d-flex">
                        <v-icon v-if="item.id" class="mt-0 mr-1" color="green darken-3" small>ri-check-</v-icon>
                        <div>

                          <p class="mb-1 text-subtitle-1 font-weight-medium">{{ item.notification.title }}</p>
                          <div v-if="item.additional != null">
                            <div
                              style="background: #bdc3c730 !important; width: 300px; font-size: 11px; line-height: 2em; "
                              class="px-2 py-1 rounded-lg text-capitalize">
                              <div class="justify-start d-flex " style="gap: 4px">
                                <div class="font-weight-bold">Program:</div>
                                <div class="font-weight-bold" style="color: #636e72">{{ item.additional.program.name }}
                                </div>
                              </div>
                              <div class="justify-start d-flex " style="gap: 4px">
                                <div class="font-weight-bold" style=" ">Topik:</div>
                                <div class="font-weight-bold" style="color: #636e72">{{ item.additional.topic.name }}
                                </div>
                              </div>
                              <div class="justify-start d-flex " style="gap: 4px">
                                <div class="font-weight-bold" style="">Aktifitas:</div>
                                <div class="font-weight-bold" style="color: #636e72">{{ item.additional.activity.name }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-muted text-body-2" v-html="item.notification.description"></div>
                          <span small class="text-caption primary--text font-weight-bold">{{
                            currentDateTime(item.created_at) }}</span>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-card-actions v-if="limitNotif <= totalNotif"
                    class="justify-center d-flex align-center subtitle-2 primary--text" style="cursor: pointer">
                    <div v-if="isLoading" class="my-1 text-center">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                    <div v-if="!isLoading" @click="getNewNotifData()" block small>
                      Tampilkan lebih banyak
                    </div>
                  </v-card-actions>
                </v-list>
              </v-card>
            </v-menu>

            <!-- PRIVATE MESSAGE -->
            <v-menu right :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-badge :content="totalPrivate" :value="totalPrivate" color="primary" overlap class="mx-3">
                  <v-icon v-bind="attrs" v-on="on" @click="getAllNotifPrivate()">
                    ri-mail-fill
                  </v-icon>
                </v-badge>
              </template>

              <v-card class="mt-3" elevation="6">
                <v-card-title class="pt-2 pb-2 mb-0 text-subtitle-1 font-weight-bold">
                  Pesan Diskusi Private
                </v-card-title>
                <v-divider></v-divider>

                <v-list dense class="" style="width: 360px;max-height: 450px;overflow-y: scroll;">
                  <v-list-item v-if="notifPrivate.length == 0">
                    <v-list-item-content>Belum ada pesan terbaru</v-list-item-content>
                  </v-list-item>

                  <v-list-item v-else v-for="(item, i) in notifPrivate" :key="i"
                    style="border-bottom:1px solid #0000001f;" @click="changeStatusPrivate(item)"
                    :class="{ 'bgRead': item.read === false }">
                    <v-badge :content="item.unread_message" :value="item.unread_message" class="mr-4" overlap>
                      <v-avatar>
                        <v-img :src="require('@/assets/images/no-pict.png')" :alt="item.trainer.fullname"></v-img>
                      </v-avatar>
                    </v-badge>
                    <v-list-item-content>
                      <div class="my-1 d-flex">
                        <div>
                          <p class="mb-1 font-weight-bold">{{ item.trainer.fullname }}</p>
                          <div
                            style="background: #bdc3c730 !important; width: 300px; font-size: 11px; line-height: 2em; "
                            class="px-2 py-1 rounded-lg text-capitalize">
                            <div class="justify-start d-flex " style="gap: 4px">
                              <div class="font-weight-bold">Program:</div>
                              <div class="font-weight-bold" style="color: #636e72">{{ item.program.title }}</div>
                            </div>
                          </div>
                          <div class="text-muted caption" v-html="item.last_message"></div>
                          <span small class="text-caption color-disable">{{ currentDateTime(item.updated_at) }}</span>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>

            <v-menu offset-y class="d-none d-md-block transparent">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="px-2 d-none d-md-block font-weight-regular" v-bind="attrs" v-on="on"
                  :disabled="!user.is_updated_profile" elevation="0" outlined>
                  <v-icon class="mr-3">ri-user-line</v-icon>
                  <span class="text-truncate d-inline-block" style="max-width: 80px">{{ user.fullname }}</span>
                  <v-icon class="mx-2" small>$dropdown</v-icon>
                </v-btn>
              </template>

              <v-list dense style="z-index: 999;">
                <v-list-item link @click="Setting">
                  <v-list-item-title>
                    <div class="d-flex">
                      <v-icon>ri-user-settings-line</v-icon>
                      <div class="pl-2 my-auto">Setting Profile</div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="openWA">
                  <v-list-item-title>
                    <div class="d-flex">
                      <v-icon>ri-briefcase-line</v-icon>
                      <div class="pl-2 my-auto">Bimbingan Prakerja</div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="goToSubscription">
                  <v-list-item-title>
                    <div class="d-flex">
                      <v-icon>ri-coupon-3-line</v-icon>
                      <div class="pl-2 my-auto">Subskripsi</div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="Logout">
                  <v-list-item-title>
                    <div class="d-flex">
                      <v-icon>ri-logout-circle-line</v-icon>
                      <div class="pl-2 my-auto">Logout</div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.mdAndUp"
              @click="dialog.menuAuth = true"></v-app-bar-nav-icon>
            <v-dialog v-model="dialog.menuAuth" fullscreen hide-overlay transition="dialog-bottom-transition">
              <v-card>
                <v-toolbar>
                  <v-toolbar-title>
                    <div class="my-1 text-left">
                      <img src="@/assets/images/logo-dash.png" alt="Avatar" class="img-yec-test" />
                    </div>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon dark @click="dialog.menuAuth = false" color="primary">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer></v-spacer>
                <v-card-text class="pa-5">
                  <v-list dense id="list-menu-user">
                    <v-list-item link @click="Beranda">
                      <v-list-item-title>
                        <v-icon>ri-home-3-line</v-icon>
                        Beranda</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="false" link @click="Prakerja">
                      <v-list-item-title>
                        <v-icon>ri-community-line</v-icon> Kelas Prakerja
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="Kelas">
                      <v-list-item-title>
                        <v-icon>ri-slideshow-line</v-icon> Kelas
                        Saya</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="">
                        <v-list-group :value="false" class="p-0">
                          <template v-slot:activator>
                            <v-list-item-content class="">
                              <v-list-item-title class="mb-2">
                                <v-icon>ri-user-line</v-icon>
                                {{ user.fullname }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                          <v-list-item link @click="Setting">
                            <v-list-item-title>
                              <v-icon>ri-user-settings-line</v-icon> Setting
                              Profile</v-list-item-title>
                          </v-list-item>
                          <v-list-item link @click="openWA">
                            <v-list-item-title>
                              <v-icon>ri-briefcase-line</v-icon> Bimbingan
                              Prakerja</v-list-item-title>
                          </v-list-item>
                          <v-list-item link @click="goToSubscription">
                            <v-list-item-title>
                              <v-icon>ri-coupon-3-line</v-icon>
                              Subskripsi</v-list-item-title>
                          </v-list-item>
                          <v-list-item link @click="Logout">
                            <v-list-item-title>
                              <v-icon>ri-logout-circle-line</v-icon>
                              Logout</v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showScan = true">
                      <v-list-item-title>
                        <img src="@/assets/images/barcode.svg" width="18" class="mr-2" />
                        Scan Absen</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-app-bar>

          <v-container fluid style="margin-top: 64px">
            <transition name="fade-up">
              <router-view v-if="loaded"></router-view>
            </transition>
          </v-container>
          <div id="footer" class="py-3 white">
            <div class="container">
              <div class="row">
                <div class="text-justify col-md-3">
                  <img :src="footer_logo" alt="Avatar" class="mb-2 img-yec-test" />
                  <div class="pe-5">
                    <div v-for="(item, i) in Object.keys(companyContacts)" :key="i">
                      <div v-if="companyContacts[item].label" class="mb-2">
                        <a class="d-flex align-center" :href="companyContacts[item].link" target="_blank">
                          <v-icon v-if="item != 'tiktok'" size="30" class="mr-2">{{ renderIcon(item) }}</v-icon>
                          <img v-else :src="require('@/assets/images/icon_tiktok.svg')" alt="icon tiktok" width="24"
                            class="mr-4">
                          <div>{{ companyContacts[item].label }}</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <p class="mb-2 fs-16 fs-xs-16 font-weight-bold">Tentang Kami</p>
                  <div>
                    <router-link to="/">Tentang LP Profesi</router-link>
                  </div>
                  <div class="pt-2">
                    <router-link to="/">Kontak Kami</router-link>
                  </div>
                </div>
                <div class="col-md-2">
                  <p class="mb-2 fs-16 fs-xs-16 font-weight-bold">Bantuan dan Panduan</p>
                  <div>
                    <router-link to="/privacy">Kebijakan Privasi</router-link>
                  </div>
                </div>
                <div class="col-md-2">
                  <p class="mb-2 fs-16 fs-xs-16 font-weight-bold">Lainnya</p>
                  <div>
                    <router-link to="/">Blog</router-link>
                  </div>
                </div>
                <div class="col-md-3">
                  <p class="mb-2 fs-16 fs-xs-16 font-weight-bold">Ikuti Kami</p>
                  <span class="mb-2">
                    <a :href="companyContactsUs['facebook'].link" target="_blank">
                      <img src="@/assets/images/FB.png" alt="Avatar" class="mr-2" style="width: 20px" />
                    </a>
                    <a :href="companyContactsUs['instagram'].link" target="_blank">
                      <img src="@/assets/images/instagram 1.png" alt="Avatar" class="mr-2" style="width: 20px" />
                    </a>
                    <a :href="companyContactsUs['whatsapp'].link" target="_blank">
                      <img src="@/assets/images/whatsapp 1.png" alt="Avatar" class="" style="width: 20px" />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="py-3 white">
            <div class="container">
              <div class="row">
                <div class="col-md-3">
                  <img
                    src="@/assets/images/logo-dash.png"
                    alt="Avatar"
                    class="mb-2 img-yec-test"
                  />
                  <p class="mb-2 fs-16 fs-xs-14">
                    <v-icon>mdi-email-outline</v-icon>
                    infoprofesimu@gmail.com
                  </p>
                  <p class="fs-16 fs-xs-14">
                    <v-icon>mdi-whatsapp</v-icon>
                    081323038500
                  </p>
                  <div v-if="false" class="pe-5">
                    <p class="fs-16 fs-xs-14">
                      Lembaga pelatihan PROFESI bekerjasama dengan para ahli untuk menghadirkan pelatihan yang mudah dipahami, inovatif dan aplikatif.
                    </p>
                    <p class="mb-0 fs-16 fs-xs-14">
                      PROFESI berupaya untuk menjadi lembaga pelatihan yang kredibel dan membantu masyarakat Indonesia secara umum memiliki keahlian yang bernilai jual sehingga mencapai kemandirian ekonomi dan peningkatan pendapatan.
                    </p>
                  </div>
                </div>
                <div class="col-md-2">
                  <p class="mb-2 fs-16 fs-xs-16 font-weight-bold">Tentang Kami</p>
                  <div>
                    <router-link to="/">Tentang LP Profesi</router-link>
                  </div>
                  <div class="pt-2">
                    <router-link to="/">Kontak Kami</router-link>
                  </div>
                </div>
                <div class="col-md-2">
                  <p class="mb-2 fs-16 fs-xs-16 font-weight-bold">Bantuan dan Panduan</p>
                  <div>
                    <router-link to="/privacy">Kebijakan Privasi</router-link>
                  </div>
                </div>
                <div class="col-md-2">
                  <p class="mb-2 fs-16 fs-xs-16 font-weight-bold">Lainnya</p>
                  <div>
                    <router-link to="/">Blog</router-link>
                  </div>
                </div>
                <div class="col-md-3">
                  <p class="mb-2 fs-16 fs-xs-16 font-weight-bold">Ikuti Kami</p>
                  <span class="mb-2">
                    <a
                      href="https://www.facebook.com/profile.php?id=100078889274240&mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/FB.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 20px"
                      />
                    </a>
                    <a
                      href="https://instagram.com/profesi_mu?igshid=NTc4MTIwNjQ2YQ=="
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/instagram 1.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 20px"
                      />
                    </a>
                    <a
                      v-if="false"
                      href="https://t.me/joinchat/A9ea-qrWsoozZDE1"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/telegram 1.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 20px"
                      />
                    </a>
                    <a href="https://wa.me/6281323038500" target="_blank">
                      <img
                        src="@/assets/images/whatsapp 1.png"
                        alt="Avatar"
                        class=""
                        style="width: 20px"
                      />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div> -->
        </v-main>
      </div>
      <v-snackbar top v-model="snackbar.state" color="primary" outlined :timeout="timeout">
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog v-model="showScan" persistent>
        <v-btn v-show="scanBarcode" @click="showScan = false" style="position: absolute; right: 0; top: 0" icon>
          <v-icon color="#000" large>ri-close-circle-line</v-icon>
        </v-btn>
        <v-card v-if="scanBarcode" class="pa-4">
          <v-card-text class="text-center pa-0">
            <p class="text-h6 font-weight-normal">
              Scan Barcode untuk absen masuk kelas yang sudah dibeli
            </p>
            <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
          </v-card-text>
        </v-card>
        <v-card v-if="result" class="rounded-xl pa-4">
          <v-card-text class="pb-0 text-center">
            <span class="text-h5">Scan QR Code Absen Kelas</span>
            <div class="pt-0 mx-auto col-md-8">
              <p class="pt-3 mb-0 text-body-1">{{ program }}</p>
            </div>
            <template v-if="success_scan">
              <v-icon large color="success" class="mb-3">ri-checkbox-circle-fill</v-icon>
              <p color="success" class="text-body-1 font-weight-bold success--text">
                Berhasil
              </p>
            </template>
            <template v-if="!success_scan">
              <v-alert type="error" text v-show="response !== null">{{
                response
              }}</v-alert>
              <v-icon large color="error" class="mb-3">ri-close-circle-line</v-icon>
              <p color="error" class="text-body-1 font-weight-bold error--text">
                Gagal
              </p>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </transition>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/firebase-messaging";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: {
    StreamBarcodeReader,
  },

  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: null,
      total: null,
      totalPrivate: null,
      notifications: [1],
      limitNotif: 10,
      totalNotif: 0,
      reads: '',
      pageTitle: "",
      notif: [],
      notifPrivate: [],
      snackbar: {
        state: false,
        text: "",
      },
      timeout: 5000,
      // title:false,
      pathnya: "",
      dialog: {
        menuAuth: false,
      },
      attend: false,
      items: [
        { title: "Beranda", icon: "$beranda", link: "/beranda" },
        // { title: "Kelas Prakerja", icon: "ri-community-line", link: "/prakerja" },
        // { title: "Privacy", icon: "ri-shield-user-line", link: "/privacy" },
        // { title: "Kelas Saya", icon: "$kelas", link: "/kelas" },
        { title: "Kelas Saya", icon: "", link: "/kelas" },
      ],
      showScan: false,
      scanBarcode: true,
      result: false,
      success_scan: null,
      program: "",
      response: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapState({
      site_data: state => state.company.site_data
    }),
    header_logo() {
      return this.site_data.logo?.url;
    },
    footer_logo() {
      return this.site_data.footer_logo?.url;
    },
    companyContacts() {
      // make sort data contact to be this order : website, instagram, youtube, email, tiktok, facebook, telegram, whatsapp
      let contact = this.site_data.contact;
      let sortedContact = {};
      // let order = ['website', 'instagram', 'youtube', 'email', 'tiktok', 'facebook', 'telegram', 'whatsapp'];
      let order = ['email', 'whatsapp'];
      order.forEach((key) => {
        if (contact[key]) {
          sortedContact[key] = contact[key];
        }
      });
      return sortedContact;
    },
    companyContactsUs() {
      // make sort data contact to be this order : website, instagram, youtube, email, tiktok, facebook, telegram, whatsapp
      let contact = this.site_data.contact;
      let sortedContact = {};
      let order = ['facebook', 'instagram', 'whatsapp'];
      order.forEach((key) => {
        if (contact[key]) {
          sortedContact[key] = contact[key];
        }
      });
      return sortedContact;
    },
  },
  created() {
    this.$root.$on("setDialog", (attend) => {
      this.attend = attend;
    });
    this.$root.$on("setPageTitle", (pageTitle) => {
      this.pageTitle = pageTitle;
    });
    // let path = "/" + this.$route.path.split("/")[1];
    this.pathnya = this.$route.path;

    // try {
    //   firebase
    //     .messaging()
    //     .requestPermission()
    //     .then(() => {
    //       firebase
    //         .messaging()
    //         .getToken()
    //         .then((token) => {
    //           this.receiveMessage();
    //         });
    //     })
    //     .catch((err) => {
    //       console.log("Unable to get token ", err);
    //     });
    // } catch (e) {
    //   console.log(e);
    // }
  },

  mounted() {
    this.getAll();
    this.getAllNotifPrivate()
    document.addEventListener("visibilitychange", async () => {
      if (!document.hidden) {
        this.getNotifUnread()
        this.getAllNotifPrivate()
      }
    }, false);
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    }
    if (this.user.id) {
      if (this.$firebaseIsSupported) {
        this.fcmToken().then(() => { })
        // this.$messaging.onTokenRefresh(this.fcmToken());
        this.$messaging.onTokenRefresh(() => {
          // console.log("onTokenRefresh...", data);
          this.fcmToken()
        });
      }
    }
  },
  methods: {
    renderIcon(value) {
      switch (value) {
        case "website":
          return "mdi-web";
        case "instagram":
          return "mdi-instagram";
        case "youtube":
          return "mdi-youtube";
        case "email":
          return "mdi-email-outline";
        case "tiktok":
          return "mdi-tiktok";
        case "facebook":
          return "mdi-facebook";
        case "telegram":
          return "mdi-telegram";
        case "whatsapp":
          return "mdi-whatsapp";
        default:
          return "mdi-web";
      }
    },
    onDecode(result) {
      if (result) {
        const data = {
          member_redeem_id: result,
        };
        this.axios
          .post("presence/v1/scan", data)
          .then(() => {
            this.success_scan = true;
            this.getDetailClass(result);
            this.result = true;
            this.scanBarcode = false;
            setTimeout(() => {
              this.success_scan = null;
              this.showScan = false;
              this.result = false;
              this.scanBarcode = true;
            }, 5000);
          })
          .catch((error) => {
            this.success_scan = false;
            this.getDetailClass(result);
            this.result = true;
            this.scanBarcode = false;
            this.res = false;
            this.response = error.response.data.message;
            setTimeout(() => {
              this.success_scan = null;
              this.result = false;
              this.scanBarcode = true;
              this.res = false;
              this.response = null;
            }, 5000);
          });
      }
    },
    getDetailClass() {
      this.loadContent = true;
      this.axios
        .get(`/users/v1/myprogram/detail/${this.$route.params.id}`)
        .then((response) => {
          this.program = response.data.data.program.title;
        });
    },
    onLoaded() { },
    getAllNotifPrivate() {
      this.getNotifPrivate();
      this.getNotifPrivateTotal();
    },
    getNewNotifData() {
      this.limitNotif = this.limitNotif + 5;
      this.getAll(this.reads);
    },
    getAll(reads) {
      this.reads = reads;
      this.getNotif();
      this.getNotifUnread();
    },
    receiveMessage() {
      try {
        firebase.messaging().onMessage((payload) => {
          payload.data = payload.data || {};
          // console.log("payload ", payload);
        });
      } catch (e) {
        console.log(e);
      }
    },
    fcmToken() {
      this.$messaging
        .requestPermission()
        .then(async () => {
          this.$messaging
            .getToken()
            .then(async (token) => {
              // console.log("token ", token);
              if (token && token != "") {
                this.axios
                  .post(`/notification/v1/member/subscribe`, {
                    token_fcm: token,
                  })
                  .then(async () => {
                    this.getNotif();
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                this.$messaging.onMessage(async (payload) => {
                  // console.log("onMessage", payload);
                  this.total = this.total + 1;
                  this.getAll();
                  // console.log("payload ", payload);
                  // const notificationTitle = payload.notification.title;
                  const notificationTitle = payload.data.title;
                  const notificationOptions = {
                    // body: payload.notification.body,
                    body: payload.data.body,
                    icon: "https://i.postimg.cc/vTFgKvRZ/favicon.png",
                  };
                  /* return navigator.serviceWorker.showNotification(
                      notificationTitle,
                      notificationOptions
                    ) */
                  navigator.serviceWorker
                    .getRegistration()
                    .then((registration) => {
                      // console.log(registration);
                      if (registration) {
                        // if (registration.length > 0) {
                        return registration.showNotification(
                          notificationTitle,
                          notificationOptions
                        );
                      }
                    });
                });
              }
            })
            .catch((err) => {
              console.log("Err ", err);
            });
        })
        .catch((err) => {
          console.log("Unable to get token ", err);
        });
    },
    getNotifPrivate() {
      this.axios
        .get("discussion/v1/discussion-private/list?limit=-1&page=1&sort=updated_at&sort_type=asc")
        .then((response) => {
          let res = response.data.data;
          this.notifPrivate = res.list;
        });
    },
    getNotifPrivateTotal() {
      this.axios
        .get("discussion/v1/discussion-private/get-total-unread")
        .then((response) => {
          this.totalPrivate = response.data.data.total_unread;
        });
    },
    async getNotif() {
      console.log(this.reads);
      this.isLoading = true;
      await this.axios
        .get(`notification/v1/member/list?limit=${this.limitNotif}&page=1&sort_by=_id&dir=desc&${this.reads ? this.reads : ''}`)
        .then((response) => {
          let res = response.data.data;
          this.notif = res.list;
          this.isLoading = false;
          this.totalNotif = res.total_data;
        });
    },
    getNotifUnread() {
      this.axios
        .get("notification/v1/member/list?limit=10&page=1&sort_by=_id&dir=desc&reads=0")
        .then((response) => {
          let res = response.data.data;
          this.total = res.total_data;
        });
    },
    currentDateTime(a) {
      return moment.utc(a).locale("ID").fromNow();
    },
    changeStatusPrivate(item) {
      if (item.unread_message > 0) {
        this.axios
          .put(`discussion/v1/discussion-private/read/${item.id}`)
          .then(() => {
            this.getAllNotifPrivate();
            this.$router.push(`/kelas?program=${item.program.id}`);
            if (this.$route.name == 'my-class') {
              setTimeout(() => {
                window.location.reload(true);
              }, 10);
            }
          });
      } else {
        this.getAllNotifPrivate();
        this.$router.push(`/kelas?program=${item.program.id}`);
        if (this.$route.name == 'my-class') {
          setTimeout(() => {
            window.location.reload(true);
          }, 10);
        }
      }
    },
    changeStatus(id) {
      this.axios
        .put(`notification/v1/member/read/${id}`)
        .then(() => {
          this.snackbar.text = `Notifikasi sudah terbaca`;
          this.snackbar.state = true;
          this.getAll(this.reads);
        });
    },
    async Logout() {
      // make axios request to logout with this url /v1/logout 
      // and then redirect to login page
      await this.axios.post("/users/v1/member/auth/logout").then(() => {
        this.$store.dispatch("logout").then(() => {
          firebase.messaging()
            .deleteToken({ token: localStorage.getItem("fcm_token") })
            .then(() => {
              this.$router.push("/");
              console.log("Token deleted successfully");
            }).catch((err) => {
              this.$router.push("/");
              console.log("Unable to delete token. ", err);
            });
          localStorage.removeItem("fcm_token");
        });
        this.dialog.menuAuth = false;
      });
    },
    Setting() {
      this.$router.push("/account");
      this.dialog.menuAuth = false;
    },
    goToSubscription() {
      this.$router.push("/subscription");
      this.dialog.menuAuth = false;
    },
    Beranda() {
      this.$router.push("/beranda");
      this.dialog.menuAuth = false;
    },
    Kelas() {
      this.$router.push("/kelas");
      this.dialog.menuAuth = false;
    },
    Prakerja() {
      this.$router.push("/prakerja");
      this.dialog.menuAuth = false;
    },
    Sertifikat() {
      this.$router.push("/sertifikat");
      window.location.reload(true);

      this.dialog.menuAuth = false;
    },
    openWA() {
      window.open(
        "https://api.whatsapp.com/send/?phone=%2B6281323038500&text&type=phone_number&app_absent=0",
        "_blank"
      );
    },
    menu() {
      this.$router.push("/");
    },
    pageChanged(segment, location = null) {
      // console.log(segment);
      // console.log(location);
      this.pathnya = this.$route.path;
      // console.log("pathpage", this.pathnya);
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }
      // window.location.reload(true);
      document.title =
        this.breadcrumbs.map((val) => val.text).join(" -> ") +
        " - " +
        'LP Profesi';
    },
  },
};
</script>

<style>
.bgRead {
  background-color: rgba(240, 83, 38, 0.1);
}

.content-wrapper {
  position: relative;
}

/*.menu-active::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  left: 0;
  background: var(--v-primary-base);
}*/
.img-yec {
  height: 50px;
  width: 100%;
  object-fit: contain;
}

/*.img-yec:hover {
  cursor: pointer;
}*/
.img-yec-test {
  height: 40px;
  object-fit: contain;
}

#list-menu-user .v-list-item {
  padding: 8px 16px;
}

/*.img-yec-test:hover {
  cursor: pointer;
}*/
</style>