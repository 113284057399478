<template>
  <div>
    <section id="banner">
      <div class="col-md-10 mx-auto">
        <v-carousel class="box-slider">
          <v-carousel-item v-for="item in site_data.sliders" :key="item.link" :src="item.data.url" :href="item.link"
            class="">
          </v-carousel-item>
          <!-- <v-carousel-item src="@/assets/images/banner/banner-new.jpeg" href="https://drive.google.com/file/d/1V7uwvhmtIYvtb8VNalshAD_1oMdIY8t4/view" target="_blank" class="">
          </v-carousel-item>
          <v-carousel-item src="@/assets/images/banner/Desktop-1.png" href="#" class="">
          </v-carousel-item>
          <v-carousel-item src="@/assets/images/banner/Desktop-2.png"  href="#" class="">
          </v-carousel-item>
          <v-carousel-item src="@/assets/images/banner/Desktop-3.png"  href="#" class="">
          </v-carousel-item> -->
        </v-carousel>
      </div>
    </section>

    <section id="kategori" class="py-5">
      <div v-show="total > 0" class="col-md-10 mx-auto">
        <div class="mb-sm-3 d-flex">
          <p class="my-auto fs-22 fs-xs-18 font-weight-bold text-capitalize">Kelas Populer</p>
          <div v-show="total === 4" class="button-selengkapnya ms-auto text-end">
            <v-btn class="px-1 font-weight-bold text-decoration-line" text color="white"
              :href="$router.resolve({ name: 'bidang-studi', query: { is_free: true } }).href">
              <span class="fs-16 fs-xs-14">Lihat Semua</span>
            </v-btn>
          </div>
        </div>
        <div class="row box-deks">
          <div v-if="loading" class="row mb-3 justify-center" style="min-height: 400px">
            <v-skeleton-loader v-for="index in 10" :key="index" class="mx-auto" width="210" type="card">
            </v-skeleton-loader>
          </div>
          <div v-else v-for="(item, index) in listKhusus" :key="index + 'B'" class="col-xl-3 col-md-3 col-sm-6 col-6">
            <v-card class="mx-auto transparent" elevation="0">
              <router-link class="" :to="{ name: 'detail-kelas', params: { id: item.id } }" @click.stop>
                <img :src="item.program_information.cover.url" class="img-program rounded-lg" />

                <v-card-subtitle class="pa-0 box-content-title">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        class="black--text font-weight-medium text-dots-2 fs-16 fs-xs-14">{{ item.title }}</span>
                    </template>
                    <span class="text-subtitle-2">{{ item.title }}</span>
                  </v-tooltip>
                  <span v-if="item.program_information.discount_price != 0" class="fs-14 color-strike"><s>Rp {{
                    toCurrency(item.program_information.discount_price) }}</s></span>
                </v-card-subtitle>

                <v-card-text class="pa-0">
                  <h3 class="color-state">Rp {{ toCurrency(item.program_information.selling_price) }}</h3>
                </v-card-text>
              </router-link>
              <v-card-actions class="pa-0 pt-2">
                <v-btn color="primary" rounded outlined class="px-3 fs-14 font-weight-medium btn-beli"
                  @click="getDialog(item.id)">
                  Beli Kelas Online
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
        <div class="row box-program mt-0 mt-sm-3">
          <div v-if="loading" class="row mb-3 justify-center" style="min-height: 400px">
            <v-skeleton-loader v-for="index in 10" :key="index" class="mx-auto" width="210" type="card">
            </v-skeleton-loader>
          </div>
          <div v-else v-for="(item, index) in listKhususLimit" :key="index + 'B'"
            class="col-xl-3 col-md-3 col-sm-6 col-6">
            <v-card class="mx-auto transparent" elevation="0">
              <router-link class="" :to="{ name: 'detail-kelas', params: { id: item.id } }" @click.stop>
                <img :src="item.program_information.cover.url" class="img-program rounded-lg" />

                <v-card-subtitle class="pa-0 box-content-title">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        class="black--text font-weight-medium text-dots-2 fs-16 fs-xs-14">{{ item.title }}</span>
                    </template>
                    <span class="text-subtitle-2">{{ item.title }}</span>
                  </v-tooltip>
                  <span v-if="item.program_information.discount_price != 0" class="fs-14 color-strike"><s>Rp {{
                    toCurrency(item.program_information.discount_price) }}</s></span>
                </v-card-subtitle>

                <v-card-text class="pa-0">
                  <h3 class="color-state">Rp {{ toCurrency(item.program_information.selling_price) }}</h3>
                </v-card-text>
              </router-link>
              <v-card-actions class="pa-0 pt-2">
                <v-btn color="primary" rounded outlined class="px-3 fs-14 font-weight-medium btn-beli"
                  @click="getDialog(item.id)">
                  Beli Kelas Online
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
      <div class="pt-6 pt-md-3 col-md-10 mx-auto" v-for="(item, index) in list" :key="index">
        <div class="mb-3 d-flex title-class-card">
          <p class="my-auto fs-20 fs-xs-18 font-weight-bold text-capitalize">{{ item.title }}</p>
          <div v-show="item.programs.length === 4" class="button-selengkapnya ms-auto text-end">
            <v-btn class="px-1 font-weight-bold text-decoration-line" text color="white"
              :href="$router.resolve({ name: 'bidang-studi', query: { studi: item.id, is_free: false } }).href">
              <span class="fs-16 fs-xs-14">Lihat Semua</span>
            </v-btn>
          </div>
        </div>
        <v-row class="">
          <v-col v-for="(program, index2) in item.programs" :key="'A' + index2" cols="6" sm="6" md="3" xl="3">
            <v-card class="mx-auto" elevation="0">
              <router-link class="" :to="{ name: 'detail-kelas-user', params: { id: program.id } }" @click.stop>
                <img :src="program.program_information.cover.url" class="img-program" />

                <v-card-subtitle class="pa-2 box-content-title">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        class="black--text font-weight-medium text-dots-2 fs-16 fs-xs-14">{{ program.title }}</span>
                    </template>
                    <span class="text-subtitle-2">{{ program.title }}</span>
                  </v-tooltip>
                  <span v-if="program.program_information.discount_price != 0" class="fs-14 color-strike"><s>Rp {{
                    toCurrency(program.program_information.discount_price) }}</s></span>
                </v-card-subtitle>

                <v-card-text class="pa-2">
                  <span v-show="false" class="fs-12 color-strike"><s>Rp 750,000 </s></span>
                  <h3 class="color-state">Rp {{ toCurrency(program.program_information.selling_price) }}</h3>
                </v-card-text>
              </router-link>
              <v-card-actions class="pa-2 pt-2">
                <v-btn color="primary" block size="x-small" class="fs-14 font-weight-medium"
                  @click="getDialog(program.id)">
                  Beli Kelas Online
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-menu
          right
          :offset-x="offset"
          elevation="2"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              rounded
              class="mx-auto text-center btn-beli"
              block
              target="_blank"                      
            >
              Beli Kelas Online
            </v-btn>
          </template>
          
          <v-list class="ml-2 pa-2 rounded-lg" elevation="2" style="border:2px solid #e0e0e0;">
            <h3 class="text-center text-subtitle-1">Pilih Platform Pembelian</h3>
            <v-divider></v-divider>
            <v-spacer class="py-2"></v-spacer>
            <v-list-item
              v-for="(item, index) in program.program_information.selling_link" :key="'B'+ index"
            >
              <v-list-item-title>
                <v-btn class="rounded-xl mb-1 white--text" outlined small color="primary" target="_blank" :href="item.url" block v-text="item.title" style="text-transform: inherit;"></v-btn>                        
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </div>
    </section>

    <v-dialog v-model="dialog" width="500" content-class="rounded-lg">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title class="fs-18 font-weight-medium">Pilih Platform Pembelian</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark icon @click="dialog = false" class="ml-auto" color="primary">
              <v-icon>ri-close-fill</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pb-0 pt-2">
          <div class="text-center mx-auto py-3">
            <div v-for="(item, index) in link" :key="'A' + index">
              <div v-if="item.title" class="mb-2 text-wrap pa-2 rounded" style="background-color: #324577;">
                <a :href="item.url" target="_blank" class="white--text">
                  {{ item.title }}
                </a>
              </div>
              <!-- <v-btn v-if="item.title"  depressed class="mb-2 white--text" color="primary" :href="item.url" target="_blank" block>{{ item.title }}</v-btn> -->
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import resources from "@/store/resources";
export default {
  name: "public-beranda",
  data: () => ({
    resources: [],
    list: [],
    listKhusus: [],
    listKhususLimit: [],
    test: [
      { title: "tokopedia", url: "https://" },
      { title: "shopee", url: "https://" }
    ],
    dialog: false,
    loading: false,
    total: null,

    link: [],
    offset: true
  }),
  created() {
    this.resources = resources;
  },
  computed: {
    ...mapState({
      site_data: (state) => state.company.site_data,
    }),
  },
  mounted() {
    this.getList();
    // this.getListKhusus();
    // this.getListKhususLimit();

    // console.log(this.$store.getters.isLoggedIn);
  },
  methods: {
    getList() {
      this.axios
        .get("users/v1/public/program/study_group?limit=10&ids=65a8c24dda15111ee9da50bf", {
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }
        })
        .then((response) => {
          this.content = true;
          this.loadContent = false;
          let res = response.data.data;
          // if (response.status == 200) {
          this.submitting = false;
          this.list = res;
          // console.log('list',response)
          // }
        });
    },
    getListKhusus() {
      this.axios
        .get(`users/v1/public/program/list?status=publish&page=1&limit=4&sort=_id&dir=desc&is_free=true&ids=65a8c24dda15111ee9da50bf`, {
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }
        })
        .then((response) => {
          let res = response.data.data;
          this.listKhusus = res.list;
          this.total = res.total
        });
    },
    getListKhususLimit() {
      this.axios
        .get(`users/v1/public/program/list?status=publish&page=1&limit=2&sort=_id&dir=desc&is_free=true&ids=65a8c24dda15111ee9da50bf`, {
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }
        })

        .then((response) => {
          let res = response.data.data;
          this.listKhususLimit = res.list;
          this.total = res.total
        });
    },
    getDialog(id) {
      this.axios
        .get(`/users/v1/public/program/detail/${id}`)
        .then((response) => {
          // let res = response.data.data;
          this.link = response.data.data.program_information.selling_link;
          this.dialog = true;
        });
    },
    login() {
      this.$router.push("/auth/login");
    },
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      // var formatter = new Intl.NumberFormat('en-IN', {
      var formatter = new Intl.NumberFormat('de-DE', {
        // style: 'currency',
        // currency: 'IDR',
        // minimumFractionDigits: 0
      });
      return formatter.format(value);
    }
  },
};
</script>

<style>
.box-slider {
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto !important;
}

#banner .v-carousel__controls>.v-item-group {
  position: absolute;
  /*left: 11% !important;*/
}

#banner .v-carousel__controls__item {
  margin: 0;
}

#banner .v-image {
  /*max-height: 360px!important;*/
  height: auto !important;
}

.img-banner {
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto !important;

  /*object-fit: contain;*/
}

.title-class-card {
  background-color: #324577;
  color: #ffffff;
  padding: 5px 10px;
  font-weight: normal;
  border-radius: 4px;
}

.title-class-card p {
  text-transform: uppercase !important;
}

.img-banner {
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto !important;

  /*object-fit: contain;*/
}

.bg-cta-login {
  background-image: url("../../../assets/images/bg-login.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 240px;
  background-size: cover;
}

.img-program {
  width: 100%;
  height: 170px;
  object-fit: fill;
  border-radius: 8px 8px 0 0;
}

.img-kategori {
  width: 100%;
}

/*@media (max-width: 768px) {
  #banner .v-image{
    height: auto!important;
  }
}*/
@media (max-width: 425px) {
  .img-program {
    height: 125px;
    object-fit: fill;
  }

  .box-slider {
    height: auto !important;
  }

  #banner .v-btn--icon.v-size--small {
    height: 15px;
    width: 15px;
  }

  #banner .v-btn__content i {
    font-size: 12px !important;
  }

  .v-carousel__item {
    height: auto !important;
  }

  .img-banner {
    height: auto !important;
  }

  .bg-cta-login {
    background-position: right !important;
    height: auto !important;
    width: 100%;
  }

  #cta-login .headline {
    font-size: 16px !important;
  }

  .btn-beli {
    font-size: 12px !important;
  }
}

@media (max-width: 375px) {}
</style>